<template>
    <div class="page1">
        <EForm :formColumns="formColumns" :rowSize="4" :optionsBtn="true" :actionBtn="false" :formData="searchForm"
               ref="form" @onSearch="onSearch" :searchFlag="true" :exportShow="false"
               @getChange="changeSelect">
        </EForm>
        <ETable :tableTitle="tableTitle"
                :tableData="tableData"
                :needPagination="true"
                :count="count"
                @changePage="changePage"
                @changeSize="changeSize"
                :page="searchForm.current" :page_size="searchForm.size">
            <el-table-column fixed="right" label="操作" width="180px">
                <template slot-scope="scope">
                    <div>
                        <EButton type="text" @click="dialogShow(scope.row.orderId)">
                            修改
                        </EButton>
                    </div>
                </template>
            </el-table-column>
        </ETable>
        <EDialog :dialogVisible="dialogVisible" title="修改"
                 @handleClose="cancelDialog" width="70%" @handleClick="saveData"
                 :disabled="saveDisabled">
            <ETable :tableTitle="tableTitle2"
                    :tableData="tableData2"
                    :needPagination="false"
            >
                <div slot="feeBeginDate" slot-scope="scope">
                    <el-date-picker
                            v-model="scope.data.feeBeginDate"
                            type="date"
                            placeholder="选择日期"
                            format="yyyy-MM-dd"
                            value-format="yyyy-MM-dd"
                            style="width: 100%"
                    >
                    </el-date-picker>
                </div>
                <div slot="feeEndDate" slot-scope="scope">
                    <el-date-picker
                            v-model="scope.data.feeEndDate"
                            type="date"
                            placeholder="选择日期"
                            format="yyyy-MM-dd"
                            value-format="yyyy-MM-dd"
                            style="width: 100%"
                    >
                    </el-date-picker>
                </div>
                <div slot="payAmount" slot-scope="scope">
                    <el-input v-model="scope.data.payAmount" :disabled="scope.data.dealType!=1"></el-input>
                </div>
                <div slot="returnAmount" slot-scope="scope">
                    <el-input v-model="scope.data.returnAmount" :disabled="scope.data.dealType!=0"
                              @blur="()=>{
            if(scope.data.returnAmount>0||Math.abs(scope.data.returnAmount)>Math.abs(scope.data.payAmount)||(scope.data.returnAmount!=0&&scope.data.returnAmount.substring(0,1)==0)){
              $message.error('输入负数，并且小于等于收款金额')
              scope.data.returnAmount=0
              return false
            }

          }"></el-input>
                </div>

            </ETable>
            <el-form>
                <el-form-item label="是否需要财务确认">
                    <el-radio-group v-model="dialogForm.isConfirm">
                        <el-radio :label="1">是</el-radio>
                        <el-radio :label="2">否</el-radio>
                    </el-radio-group>
                </el-form-item>
            </el-form>
        </EDialog>
    </div>
</template>

<script>
  import ETable from '@/components/ETable'
  import Http from "@/service/http";
  import EDialog from '@/components/EDialog'
  import EButton from '@/components/EButton'
  import EForm from '@/components/EForm'
  import {identity_type, status} from '@/assets/js/config'
  import vxRule from "@/assets/js/formValidate";
  import {otherMixin} from "@/components/mixin";

  export default {
    name: 'shopInfo',
    mixins: [otherMixin],
    data() {
      return {
        tableTitle: [
          // {
          //   label: '交费单号',
          //   prop: 'orderNum'
          // },

          {
            label: '区域',
            prop: 'areaName'
          },
          {
            label: '位置',
            prop: 'placeName',
          },
          {
            label: '摊位号',
            prop: 'boothCode'
          },
          {
            label: '商户名称',
            prop: 'tenantName'
          },
          {
            label: '总金额',
            prop: 'totalAmount'
          },
          {
            label: '费用笔数',
            prop: 'feeNum'
          },
          {
            label: '录入人',
            prop: 'createUserCn'
          },
          {
            label: '录入时间',
            prop: 'createTime'
          },
          {
            label: '财务操作人',
            prop: 'confirmPersonName'
          },
          {
            label: '财务退回时间',
            prop: 'confirmTime'
          },
          {
            label: '备注',
            prop: 'remark'
          },
        ],
        formColumns: [
          {
            title: '摊位编号',
            type: 'text',
            property: 'boothCode',
            show: true
          },
          {
            title: '财务操作人',
            type: 'text',
            property: 'confirmPersonName',
            show: true
          },
          {
            title: '财务退回开始时间',
            type: 'datePicker',
            property: 'confirmTimeStart',
            show: true
          },
          // {
          //   title: '租/退',
          //   type: 'select',
          //   property: 'isRent',
          //   show: true,
          //   options: [
          //     {
          //       label: '全部',
          //       value: '',
          //     },
          //     {
          //       label: '租',
          //       value: '1',
          //     },
          //     {
          //       label: '退',
          //       value: '2',
          //     }
          //   ]
          // },

          {
            title: '区域',
            type: 'select',
            property: 'areaId',
            show: true,
            options: []
          },
          {
            title: '录入开始时间',
            type: 'datePicker',
            property: 'createTimeStart',
            show: true
          },
          {
            title: '财务退回截止时间',
            type: 'datePicker',
            property: 'confirmTimeEnd',
            show: true
          },
          {
            title: '录入人',
            type: 'text',
            property: 'createUser',
            show: true
          },
          {
            title: '位置',
            type: 'select',
            property: 'placeId',
            show: true,
            options: []
          },
          {
            title: '录入截止时间',
            type: 'datePicker',
            property: 'createTimeEnd',
            show: true
          },
        ],
        tableData: [],
        searchForm: {
          boothCode: null,
          confirmPersonName: null,
          confirmTimeStart: null,
          areaId: null,
          createTimeStart: null,
          confirmTimeEnd: null,
          createUser: null,
          placeId: null,
          createTimeEnd: null,
          confirmStatus: 4,
          current: 1,
          size: 10,
        },
        tableTitle2: [
          {
            label: '收费项目名称',
            prop: 'takeReturnProjectName'
          },
          {
            label: '费用起始日期',
            prop: 'feeBeginDate',
            type: 'slot',
            width: '160px'
          },
          {
            label: '费用截止日期',
            prop: 'feeEndDate',
            type: 'slot',
            width: '160px'
          },
          {
            label: '收费金额',
            prop: 'payAmount',
            type: 'slot'
          },
          {
            label: '期数',
            prop: 'periods',
          },
          {
            label: '收/退',
            prop: 'dealTypeCn',
          },
          {
            label: '退费金额',
            prop: 'returnAmount',
            type: 'slot'
          },
        ],
        tableData2: [],
        count: null,
        dialogVisible: false,
        options: [],
        areaOptions: [],
        locationOptions: [],
        dialogForm: {
          orderId: '',
          isConfirm: 1
        }
      }
    },
    watch: {},
    created() {
      this.getData()
      this.getAreaLabel()
    },
    components: {ETable, EDialog, EButton, EForm},
    beforeMount() {

    },
    methods: {
      changeSelect(prop) {
        if (prop == 'areaId') {
          this.getPlaceLabel()
        }
      },
      async dialogShow(orderId) {
        this.dialogVisible = true
        this.dialogForm.orderId = orderId
        let res = await Http.cmorderDetail({id: orderId})
        if (res.code == 200) {
          this.tableData2 = res.data.items
        }
      },
      saveData() {
        this.orderUpdate()
        // this.$refs['form'].validate((valid, object) => {
        //   console.log(object)
        //   if (valid) {
        //     this.orderUpdate()
        //   } else {
        //     return false;
        //   }
        // })
      },
      onSearch() {
        this.searchForm.current = 1
        this.getData()
      },
      changePage(current) {
        this.searchForm.current = current
        this.getData()
      },
      changeSize(size){
        this.searchForm.size = size
        this.getData()
      },
      cancelDialog() {
        this.dialogVisible = false
        this.dialogForm = {
          orderId: '',
          isConfirm: 1
        }
        this.tableData2 = []
      },
      async getData() {
        let res = await Http.cmorderPage(this.searchForm)
        if (res.code == 200) {
          this.tableData = res.data.records
          this.count = res.data.total
        }
      },
      //新增或者修改请求
      async orderUpdate() {
        this.setDisabled(true)
        let res = await Http.orderUpdate({
          ...this.dialogForm,
          items: this.tableData2
        })
        if (res.code == 200) {
          this.$message.success(res.msg);
          this.setDisabled(false)
          this.cancelDialog()
          this.getData()
        }
      },

      //获取位置下拉
      async getPlaceLabel() {
        let res = await Http.getPlaceLabel({
          areaId: this.searchForm.areaId,
          status: null
        })
        if (res.code == 200) {
          this.formColumns[7].options = res.data
          this.locationOptions = res.data
        }
      },
      //获取区域下拉
      async getAreaLabel() {
        let res = await Http.getAreaLabel({
          status: null
        })
        if (res.code == 200) {
          this.formColumns[3].options = res.data
          this.areaOptions = res.data
        }
      },
    }
  }
</script>

<style lang="scss" scoped>
    .ws {
        width: 100%;
    }
</style>
